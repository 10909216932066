<template>
  <a-card class="card-area">
    <div class="operator">
      <a-button @click="addNews" type="primary" ghost>新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <a-table 
      ref="TableInfo" 
      bordered 
      :loading="loading" 
      :scroll = "{ x: 900 }"
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" 
      :pagination="pagination" 
      @change="handleTableChange" 
      :columns="columns" 
      :data-source="dataSource"
      :rowKey="(record) => record.id"
    >
      <a-tag slot="range" slot-scope="text">{{text=='0'?"内部":(text=='1'?"外部":"未知")}}</a-tag>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editNews(record)" title="修改"></a-icon>
        &nbsp;
        <a-icon type="eye" theme="twoTone" twoToneColor="#42b983" @click="view(record)" title="查看"></a-icon>
      </template>
    </a-table>
    <news-edit ref="newsEdit" @close="handleNewsEditClose" @success="handleNewsEditSuccess" :newsEditVisiable="newsEditVisiable"></news-edit>
    <news-add ref="newsAdd" @close="handleNewsAddClose" @success="handleNewsAddSuccess" :newsAddVisiable="newsAddVisiable"></news-add>
    <news-info ref="newsInfo" :infoVisible="infoVisible" @_close="handleInfoClose" ></news-info>
  </a-card>
</template>

<script>
import NewsAdd from "./NewsAdd"
import NewsEdit from "./NewsEdit"
import NewsInfo from "./NewsInfo"

const columns = [
  {
    title: "新闻标题",
    dataIndex: "title",
    // ellipsis: true
    minWidth: 300
  },

  {
    title: "浏览数（人）",
    dataIndex: "viewNumbers",
    // ellipsis: true
    width: 150
  },
  {
    title: "点赞数（人）",
    dataIndex: "likesNum",
    // ellipsis: true
    width: 150
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    // ellipsis: true
    width: 200
  },
  {
    title: "创建人员",
    dataIndex: "createUsername",
    // ellipsis: true
    width: 100
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    width: 80,
    fixed: "right",
  }
];

export default {
  components: {
    NewsAdd,
    NewsEdit,
    NewsInfo
  },
  data() {
    return {
      dataSource: [],
      columns,
      selectedRowKeys: [],
      newsAddVisiable: false,
      newsEditVisiable: false,
      queryParams: {},
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      infoVisible: false
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }

      this.$get("company_news", {
        ...params
      }).then((r) => {
        // console.log("获取到的公司新闻列表", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        let rows = r.data.rows;
        // if(rows) {
        //   for (const key in rows) {
        //     rows[key]['key'] = +key + 1;
        //   }
        //   this.dataSource = rows;
        // }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      });
    },
    view(record) {
      // console.log("查看详情 ==> ", record);
      this.infoVisible = true;
      this.$refs.newsInfo.setFormFields(record);
    },
    handleInfoClose() {
      this.infoVisible = false;
    },
    addNews() {
      this.newsAddVisiable = true;
    },
    handleNewsAddSuccess() {
      this.newsAddVisiable = false;
      this.$message.success("新闻添加成功！");
      this.fetch();
    },
    handleNewsAddClose() {
      this.newsAddVisiable = false;
    },
    editNews(record) {
      this.newsEditVisiable = true;
      this.$refs.newsEdit.setCompanyNewsFields(record);
    },
    handleNewsEditSuccess() {
      this.$message.success("新闻修改成功！");
      this.newsEditVisiable = false;
      this.fetch();
    },
    handleNewsEditClose() {
      this.newsEditVisiable = false;
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`company_news/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("企业新闻删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>

